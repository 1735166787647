import React from "react"
import styles from "../css/postTemplate.module.css"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Carousel2 from '../components/Projects/carousel/carousel2'

export const query = graphql`
 {
  file(relativePath: {eq: "DrinkMe.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}

`

const Drinkme = ({ data }) => {
    const author = 'Yukuri'
    const title = 'DrinkMe'
    return (
        <Layout>
            <section className={styles.template}>
                <Link to="/projects" className={styles.link}>
                    {" "}
            back to projects
          </Link>
                <div className={styles.info}>
                    <h1>{title}</h1>
                    <h4>
                        <span>by {author}</span>
                    </h4>
                </div>
                <Image fluid={data.file.childImageSharp.fluid} />          <div className={styles.content}>
                    <div>Este proyecto consiste en la creación del naming y el branding de una marca.
    Elegí una marca ecológica de tes, destinada un público joven, ofreciendo mezclas e infusiones
    para diferentes momentos del día.
    
    ¿Por qué Drink Me?
    El nombre proviene de las pociones de Alicia en el País de las Maravillas, en estas había
    etiquetas con el mensaje "bébeme". La línea estética de la marca es brujeril y natural, pensando
    en ese mensaje que te invita a sentarte, relajarte y beber un delicioso té.
    
    Para este manual corporativo tuve que realizar el diseño de la papelería, incluyendo
    el tarjetón de invitación con un aspecto llamativo en los colores corporativo y dorado.
    
    También el packaging para los diferentes productos de la marca y el merchandising de la misma.
    
    Proyecto realizado con Procreate, Adobe Photoshop y Adobe Illustrator.</div>

                </div>                    <Carousel2 />

            </section>
        </Layout>
    )
}

export default Drinkme
